// React imports
import { useState } from 'react'

// Remix imports
import { Link } from '@remix-run/react'
import type { HeadersFunction, LoaderFunction } from '@remix-run/server-runtime'

// Third party imports

// Generated imports

// App imports
import Button from '~/components/buttons/Button'
import Modal from '~/components/modals/Modal'
import P1 from '~/components/typography/P1'
import TextLink from '~/components/typography/TextLink'
import { fetchGlobalGQL } from '~/fetch.server'
import useGlobal from '~/hooks/useGlobal'
import useLang from '~/hooks/useLang'
import IconCartSvgUrl from '~/static/svg/IconCart.svg'
import { CACHE_CONTROL } from '~/utils/cacheControl'
import { loaderJson } from '~/utils/jsonResponse.server'
import urlReverse from '~/utils/urlReverse'

const VIEW_CACHE = CACHE_CONTROL.ONE_HOUR

// ============================================================================
// HTML =======================================================================
// ============================================================================
export const AddedToCartModal: React.FC = () => {
  const [hideModal, setHideModal] = useState(false)
  const lang = useLang()
  const global = useGlobal()
  const trans = global.trans

  return (
    <Modal
      size="small"
      hideModal={hideModal}
    >
      <div className="flex h-[200px] flex-col items-center justify-center text-center">
        <img
          src={IconCartSvgUrl}
          alt={trans.CartIcon}
          className="w-[39px]"
        />
        <P1 className="mt-6">
          <b>{trans.ItemAddedToCart}</b>
        </P1>

        <Link
          to={urlReverse('cart', { lang })}
          prefetch="intent"
          className="mt-6"
        >
          <Button>{trans.GoToCart}</Button>
        </Link>

        <TextLink
          className="mt-6"
          onClick={() => {
            setHideModal(true)
          }}
        >
          {trans.ContinueBrowsing}
        </TextLink>
      </div>
    </Modal>
  )
}

export default AddedToCartModal

// ============================================================================
// LOADERS ====================================================================
// ============================================================================
export let loader: LoaderFunction = async ({ request, params }) => {
  const { global, headers } = await fetchGlobalGQL(request, params)
  return loaderJson({ global }, VIEW_CACHE, { headers })
}

// ============================================================================
// HEADERS ====================================================================
// ============================================================================
export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    'Cache-Control': VIEW_CACHE,
  }
}
